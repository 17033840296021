// REF: https://github.com/steemit/condenser/blob/master/src/app/utils/BadActorList.js
const BadActorList = `
aalpha
aappreciator
abits
acx
aex.com
alha
alhpa
allcoin.com
alpah
alph
alphha
alppha
alueup
apha
aplha
apppreciator
apprceiator
apprciator
apprecaitor
apprecator
apprecciator
appreciaator
appreciaor
appreciaotr
appreciater
appreciatoor
appreciatorr
appreciatr
appreciatro
appreciattor
appreciiator
apprecitaor
apprecitor
appreeciator
appreiator
appreicator
apprreciator
apreciator
aprpeciator
ausbitban
ausbltbank
avlueup
battrex
bbithumb.hot
bbittrex
bblocktrades
bboomerang
bbooster
bbuildawhale
bcex
bellyrub
berniesandlers
bernieslanders
berniestandards
bernlesanders
bettrex
bihtrex
bihtumb.hot
bihumb.hot
biithumb.hot
biitrex
biittrex
bildawhale
binan
binanc
binance
binance.com
binanced
binancee
binances
binanse
binnance
bit-z
bitex
bitfinex
bitfinex.com
bitfinix
bitflip
bithhumb.hot
bithmb.hot
bithmub.hot
bithub.hot
bithubm.hot
bithum.bhot
bithumb
bithumb-deposit
bithumb-exchange
bithumb-pro
bithumb.com
bithumb.hoot
bithumb.hott
bithumb.hto
bithumb.oht
bithumbb.hot
bithummb.hot
bithuumb.hot
bitifinex
bitkrx
bitnaru
bitre
bitreex
bitrex
bitrexx
bitrix
bitrrex
bitrtex
bitrx
bitsane
bitsane.com
bitstamp
bitstamp.net
bitt
bitteex
bitter
bitterex
bitterx
bittex
bitthai
bittr
bittrax
bittre
bittrec
bittrecs
bittrecx
bittred
bittreex
bittrek
bittres
bittresx
bittret
bittrev
bittrex-deposit
bittrex-pro
bittrex.com
bittrexc
bittrexchange
bittrexe
bittrexs
bittrext
bittrexx
bittrexxx
bittrez
bittrix
bittrrex
bittrrx
bittrx
bittrxe
bitttec
bitttex
bitttrex
bittylicious
bittylicious.com
bituhmb.hot
biuldawhale
blcktrades
blcoktrades
bleutrade
bleutrade.com
bllocktrades
bloccktrades
block-trades
block-trades-com
blockktrades
blockrade
blockrades
blockrtades
blocktades
blocktardes
blocktraades
blocktrad
blocktraddes
blocktrade
blocktraded
blocktradee
blocktradees
blocktrader
blocktraders
blocktrades-com
blocktrades-info
blocktrades-us
blocktrades.com
blocktrades.info
blocktrades.us
blocktradess
blocktradesss
blocktradez
blocktrading
blocktrads
blocktradse
blocktraeds
blocktraes
blocktrdaes
blocktrdes
blocktredes
blocktrrades
blockttrades
bloctkrades
bloctrades
blokctrades
bloktrades
bloocktrades
bocktrades
bolcktrades
bomerang
bomoerang
booemrang
booerang
boomeang
boomearng
boomeerang
boomeraang
boomerag
boomeragn
boomerangg
boomeranng
boomernag
boomerrang
boommerang
boomreang
booomerang
boooster
booser
boosetr
boostar
booste
boosterr
boostr
boostre
boostter
bootser
bosoter
boster
btc-alpha
btc-alpha.com
btc38
btcalpha
btcmarkets
btcmarkets.net
bthumb.hot
btihumb.hot
btitrex
btrex
bttrex
bttrx
buidawhale
buidlawhale
buiildawhale
builadwhale
buildaawhale
buildahale
buildahwale
buildawahle
buildawale
buildawhaale
buildawhael
buildawhalee
buildawhalle
buildawhhale
buildawhile
buildawhlae
buildawwhale
builddawhale
buildwahale
buildwhale
builldawhale
buldawhale
bulidawhale
buobi-pro
buuildawhale
c-cex
c-cex.com
canadiancoconut
ccex
cexio
changellly
changelly.com
changely
cinpayments.net
cionpayments.net
coin-room
coinapyments.net
coinayments.net
coinbas
coinbase
coinbase.com
coinbased
coinegg
coinegg.com
coinpaments.net
coinpamyents.net
coinpayemnts.net
coinpayents.net
coinpaymens.net
coinpaymenst.net
coinpayment
coinpayment.snet
coinpayments
coinpayments.ent
coinpayments.nte
coinpaymetns.net
coinpaymnets.net
coinpaymnts.net
coinpia
coinpyaments.net
coinroom.com
coinsmarkets
coinsmarkets.com
coinspot
coinzest
coipayments.net
coipnayments.net
community-coin
conipayments.net
conpayments.net
coolcoin.com
curi
curied
curies
curing
d-tube
dcrypto8
ddeepcrypto8
decrypto8
deep8
deepccrypto8
deepcripto8
deepcrpto8
deepcrrypto8
deepcrypo8
deepcryppto8
deepcrypt08
deepcrypt8
deepcrypto-8
deepcrypto0
deepcrypto7
deepcrypto88
deepcrypto9
deepcryptoo8
deepcryptos
deepcryptos8
deepcryptto8
deepcryto8
deepcrytpo8
deepcryypto8
deepcypto8
deeppcrypto8
deeprypto8
depcrypto8
donkeypon
edepcrypto8
eepcrypto8
etherdelta
etherdelta.com
exrates
exx.com
feepcrypto8
freeewallet
freewalet.org
freewaller
frewallet
fyrstiken
gatecoin.com
gatehub
gatehub.net
gdax.com
gemini.com
ggopax-deposit
goapx-deposit
goax-deposit
good-kama
goopax-deposit
gopa-deposit
gopa-xdeposit
gopaax-deposit
gopax-ddeposit
gopax-deopsit
gopax-deosit
gopax-depoist
gopax-depoit
gopax-depoosit
gopax-deposiit
gopax-depositt
gopax-depossit
gopax-depost
gopax-deposti
gopax-depposit
gopax-depsit
gopax-depsoit
gopax-dpeosit
gopax-dposit
gopax-edposit
gopax-eposit
gopax-hot
gopaxd-eposit
gopaxx-deposit
goppax-deposit
gopx-deposit
gopxa-deposit
gpax-deposit
gpoax-deposit
gtg.witnesses
herising
hhuobi-pro
hitbtc-deposit
hitbtc-pro
hitbtc.com
hitbtcexchange
hterising
huobbi-pro
huobi-ppro
huobi-pr0
huobi-proo
huobi-prro
huobi.pro
huobii-pro
huobl-pro
huoobi-pro
huuobi-pro
ibthumb.hot
ibttrex
idex.market
imnnowbooster
inance
innowbooster
ithumb.hot
ittrex
ittrexx
kcx
kevinwon
kocostock
koinex
kraken
kraken.com
kucoi
kucoin
kucoinn
kucoins
lapha
lbocktrades
linkcoin
litebit
little-pepper
livecoin.com
livecoinnet
livingroomofsato
localtrade
localtrade.pro
locktrades
lpha
martsteem
mercatox.com
miinnowbooster
minnnowbooster
minnobooster
minnobwooster
minnooboster
minnoowbooster
minnowboooster
minnowbooser
minnowboosetr
minnowboosster
minnowboost
minnowbooste
minnowboosted
minnowboosteer
minnowboosterr
minnowboosters
minnowboostr
minnowboostre
minnowboostter
minnowbootser
minnowbosoter
minnowboster
minnowbuster
minnowhelp
minnowoboster
minnowooster
minnowpooster
minnows
minnowsuport
minnowsupports
minnowwbooster
minnwbooster
minnwobooster
minobooster
minonwbooster
minowbooster
minowboster
minowhelper
minowsupport
mminnowbooster
mmyupbit
mninowbooster
msartsteem
my-upbit
myupbbit
myupbiit
myupbitt
myupblt
myuppbit
myuupbit
myyupbit
neraex
neraex.com
nextgencrypted
nextgencryptos
obomerang
oboster
ocky1
oenledger-dex
oepnledger-dex
ogpax-deposit
oinpayments.net
okex.com
olonie
oloniex
oomerang
oopenledger-dex
ooster
opeenledger-dex
opeledger-dex
opelnedger-dex
openedger-dex
openeldger-dex
openldeger-dex
openldger-dex
openleddger-dex
openledegr-dex
openleder-dex
openledge-dex
openledge-rdex
openledgeer-dex
openledger-ddex
openledger-de
openledger-deex
openledger-dx
openledger-dxe
openledger-edx
openledger-ex
openledger-pro
openledgerd-ex
openledgerdex
openledgerr-dex
openledgr-dex
openledgre-dex
openleedger-dex
openlegder-dex
openleger-dex
openlledger-dex
opennledger-dex
opnledger-dex
oppenledger-dex
opstpromoter
orcky1
ostpromoter
p-funk
paloniex
papreciator
paypals
penledger-dex
pextokens
pfuck
piloniex
plolniex
ploniex
plooniex
poenledger-dex
pokoniex
polaniex
poleniex
poliniex
polionex
pollniex
polloniex
polloniexx
pollonix
polniex
polnoiex
polobiex
poloex
poloiex
poloinex
pololniex
polomiex
polon
poloneex
poloneiex
poloneix
polonex
poloni
poloniax
polonie
poloniec
poloniecs
polonied
poloniee
polonieex
poloniek
polonieks
polonies
poloniet
poloniets
poloniew
poloniex.com
poloniexcold
poloniexcom
poloniexe
poloniexs
poloniext
poloniexwalle
poloniexwallet
poloniexx
poloniexxx
poloniey
poloniez
poloniiex
poloniix
poloniks
poloniox
polonium
polonix
polonixe
polonixx
polonniex
polonoiex
polonox
polonx
polonyex
polooniex
poluniex
pomobot
pononiex
poolniex
pooloniex
pooniex
poooniex
poostpromoter
poponiex
pormobot
posptromoter
posstpromoter
postpormoter
postppromoter
postprmooter
postprmoter
postprommoter
postpromoer
postpromooter
postpromote
postpromoteer
postpromoterr
postpromotor
postpromotre
postpromotter
postpromter
postpromtoer
postproomoter
postproomter
postprooter
postprromoter
postromoter
posttpromoter
potpromoter
potspromoter
ppostpromoter
ppreciator
ppromobot
prmobot
prmoobot
promboot
prombot
prommobot
promobbot
promobo
promoboot
promobott
promobt
promobto
promoobot
promoobt
promoot
proobot
proombot
proomobot
prromobot
pse
psotpromoter
pstpromoter
ptakundamianshow
ptunk
puloniex
qryptos
qryptos.com
randomwhale
randowale
randowhal
randwhale
rcky1
rcoky1
rdex
rduex
rendowhale
roccky1
rock1y
rockky1
rockyy1
rocy1
rocyk1
rokcy1
romobot
roocky1
rpomobot
rrocky1
rrudex
ruddex
rudeex
rudexx
rudx
rudxe
ruedx
ruudex
samrtsteem
sartsteem
scoin
seem
seemit
seemit2
seepcrypto8
setem
setemit
setemit2
shapeshif
shapeshift
smaartsteem
smarrtsteem
smarstteem
smartseem
smartsetem
smartstee
smartsteeem
smartsteemm
smartsteme
smartstteem
smartteem
smatrsteem
smmartsteem
smratsteem
smrtsteem
ssmartsteem
ssteemit2
steampunks
steeemit2
steeimt
steeimt2
steeit
steeit2
steemi2
steemi2t
steemitpay
steemitt2
steemmit2
steempay
steempays
steemt2
steemti2
steemupbit
stemeit
stemeit2
stemit1
stemit2
stemit3
stemit4
stteem
stteemit2
sweetsj
sweetssj
sweetsss
sweetsssjs
sweetssssj
swetsssj
tdax
tdax.com
teamsteam
techno-comanche
technocommander
teemit2
tehrising
terising
thealien
thebiton
theerising
theirsing
theising
theriing
theriising
theriisng
therisig
therisign
therisiing
therisingg
therisinng
therisng
therisnig
therissing
therocktrading
therrising
thersiing
thersing
thherising
threising
thrising
tidex.com
topbtc
tseem
tseemit
tseemit2
ttherising
ttrex
ubildawhale
udex
uhobi-pro
uildawhale
umewhale
umpewhale
uobi-pro
upbbit
upbi
upbit
upbit.com
upbits
upbitt
upblt
upemwhale
upewhale
upm
upmee
upmeewhale
upmehale
upmehwale
upmewahle
upmewhaale
upmewhae
upmewhael
upmewhalee
upmewhalle
upmewhhale
upmewhlae
upmewhle
upmewwhale
upmme
upmmewhale
upmwehale
upmwhale
uppme
uppmee
uppmewhale
uupmewhale
vaalueup
valeup
valeuup
vallueup
valueeup
valuep
valuepu
valueu
valueupp
valuuep
valuueup
valuup
vaueup
vauleup
viabtc
vittrex
vlaueup
vlueup
vvalueup
wallet.bitshares
whaleshare
www.aex.com
www.binance.com
www.bit-z.com
www.bitfinex.com
www.bithumb.com
www.bitstamp.net
www.bittrex.com
www.coinbase.com
www.coinegg.com
www.coolcoin.com
www.exx.com
www.gatecoin.com
www.gatehub.net
www.gdax.com
www.huobi.pro
www.kraken.com
www.livecoin.net
www.okex.com
www.poloniex.com
www.qryptos.com
www.xbtce.com
xbtce.com
ymupbit
yobit
yobit.net
youbit
yunbi
zenieix
steemhunt
steenhunt
steemhumt
steenhumt
steeemhunt
stemhunt
`.trim().split('\n');

// REF: https://github.com/steemit/condenser/blob/master/src/app/utils/ChainValidation.js#L6
export function validateAccountName(value) {
    let i, label, len, length, ref;

    if (!value) {
        return "Account name should not be empty.";
    }

    if (value !== value.toLowerCase()) {
        return "Account name should be lower case letters.";
    }

    length = value.length;
    if (length < 3) {
        return "Account name should be longer.";
    }
    if (length > 16) {
        return "Account name should be shorter.";
    }
    if (BadActorList.includes(value)) {
        return "Use caution sending to this account. Please double check your spelling for possible phishing.";
    }
    ref = value.split('.');
    for (i = 0, len = ref.length; i < len; i++) {
        label = ref[i];
        if (!/^[a-z]/.test(label)) {
            return "Each account segment should start with a letter.";
        }
        if (!/^[a-z0-9-]*$/.test(label)) {
            return "Each account segment should have only letters, digits, or dashes.";
        }
        if (/--/.test(label)) {
            return  "Each account segment should have only one dash in a row.";
        }
        if (!/[a-z0-9]$/.test(label)) {
            return "Each account segment should end with a letter or digit.";
        }
        if (!(label.length >= 3)) {
            return "Each account segment should be longer.";
        }
    }
    return null;
}
